<template>
    <html>

    <head>
        <meta name="viewport" content="width==device-width, initial-scale=1.0" />
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="Generator" content="Microsoft Word 15 (filtered)" />
    </head>

    <body lang="EN-US" link="blue" vlink="#954F72" style="word-wrap: break-word">
        <div class="WordSection1">
            <div>
                Version : {{ version }}
            </div>
            <div>
                <pre>{{ $infoJson[0] }}</pre>
            </div>
        </div>
    </body>

    </html>
</template>
<script>
// import json from './info.json'
export default {
    data() {
        return {
            version: process.env.VUE_APP_GIT_VERSION,
        }
    }
}

</script>
<style>
/* Font Definitions */
@font-face {
    font-family: Wingdings;
    panose-1: 5 0 0 0 0 0 0 0 0 0;
}

@font-face {
    font-family: "Cambria Math";
    panose-1: 2 4 5 3 5 4 6 3 2 4;
}

@font-face {
    font-family: Calibri;
    panose-1: 2 15 5 2 2 2 4 3 2 4;
}

/* Style Definitions */
p.MsoNormal,
li.MsoNormal,
div.MsoNormal {
    margin-top: 0in;
    margin-right: 0in;
    margin-bottom: 8pt;
    margin-left: 0in;
    line-height: 107%;
    font-size: 11pt;
    font-family: "Calibri", sans-serif;
}

p.MsoListParagraph,
li.MsoListParagraph,
div.MsoListParagraph {
    margin-top: 0in;
    margin-right: 0in;
    margin-bottom: 8pt;
    margin-left: 0.5in;
    line-height: 107%;
    font-size: 11pt;
    font-family: "Calibri", sans-serif;
}

p.MsoListParagraphCxSpFirst,
li.MsoListParagraphCxSpFirst,
div.MsoListParagraphCxSpFirst {
    margin-top: 0in;
    margin-right: 0in;
    margin-bottom: 0in;
    margin-left: 0.5in;
    line-height: 107%;
    font-size: 11pt;
    font-family: "Calibri", sans-serif;
}

p.MsoListParagraphCxSpMiddle,
li.MsoListParagraphCxSpMiddle,
div.MsoListParagraphCxSpMiddle {
    margin-top: 0in;
    margin-right: 0in;
    margin-bottom: 0in;
    margin-left: 0.5in;
    line-height: 107%;
    font-size: 11pt;
    font-family: "Calibri", sans-serif;
}

p.MsoListParagraphCxSpLast,
li.MsoListParagraphCxSpLast,
div.MsoListParagraphCxSpLast {
    margin-top: 0in;
    margin-right: 0in;
    margin-bottom: 8pt;
    margin-left: 0.5in;
    line-height: 107%;
    font-size: 11pt;
    font-family: "Calibri", sans-serif;
}

.MsoChpDefault {
    font-family: "Calibri", sans-serif;
}

.MsoPapDefault {
    margin-bottom: 8pt;
    line-height: 107%;
}

@page WordSection1 {
    size: 8.5in 11in;
    margin: 1in 1in 1in 1in;
}

div.WordSection1 {
    page: WordSection1;
}

/* List Definitions */
ol {
    margin-bottom: 0in;
}

ul {
    margin-bottom: 0in;
}
</style>
